import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import ContentSections from '../components/pagebuilder/content-sections';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        keywords
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
    page: sanityPage(slug: { current: { eq: "home" } }) {
      content: contentArray {
        ...PageContent
      }
      title
      seoImage {
        asset {
          ... on SanityImageAsset {
            _id
            url
            gatsbyImageData(layout: FIXED, width: 1024)
          }
        }
      }
      seoKeywords
      seo {
        focus_keyword
        meta_description
        seo_title
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  // scroll to top of page by default overriding gatsby router trying to restore scroll position
  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, [data]);

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  const { site, page } = data;

  // console.log('page: ', page);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <>
      <SEO
        title={page.seo.seo_title || site.title || config.title}
        description={page.seo.meta_description}
        keywords={page.seoKeywords}
        seoImage={page.seoImage?.asset?.img?.src}
      />
      {page && <ContentSections content={page.content} slug={'home'} />}
    </>
  );
};

export default IndexPage;
